@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600;700&family=K2D:wght@100;200;400;500;600;700;800&family=Roboto:wght@300;400;500;700;900&family=Ubuntu:wght@400;500;700&family=Volkhov:wght@400;700&display=swap");

body {
  font-family: "K2D", sans-serif;
  background-color: #02121d;
}
.jura {
  font-family: "Jura", sans-serif;
}
.k2d {
  font-family: "K2D", sans-serif;
}
.roboto {
  font-family: "Roboto", sans-serif;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif;
}
.volkhov {
  font-family: "Volkhov", serif;
}
.css-13cymwt-control {
  background-color: #02121d !important;
  color: #78909c !important;
  font-family: "K2D", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border: 0px solid transparent !important;
}
.css-13cymwt-control span {
  width: 0px !important;
}
.css-13cymwt-control svg {
  fill: #78909c;
}
.css-13cymwt-control .css-1dimb5e-singleValue {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  color: #78909c !important;
  font-family: "K2D", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.fillter-select .css-13cymwt-control {
  background-color: #274050 !important;
  color: #78909c !important;
  font-family: "K2D", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border: 0px solid transparent !important;
  border-radius: 20px !important;
  overflow: hidden;
}
.fillter-select .css-13cymwt-control .css-1dimb5e-singleValue {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  color: #ffffffc5 !important;
  font-family: "K2D", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px !important;
}
.fillter-select .css-13cymwt-control svg {
  fill: #ffffff;
}
.red-grident {
  background: rgb(138, 111, 78);
  background: linear-gradient(
          90deg,
          rgba(138, 111, 78, 1) 0%,
          rgba(207, 17, 17, 1) 100%
  );
}
.cart-w-set canvas {
  width: 100% !important;
}
.bg-gredient::after {
  background: rgb(113, 31, 218);
  background: linear-gradient(
          90deg,
          rgba(113, 31, 218, 1) 0%,
          rgba(41, 137, 168, 1) 100%
  );
}
.group:hover .btn {
  background: rgb(113, 31, 218);
  background: linear-gradient(
          90deg,
          rgba(113, 31, 218, 1) 0%,
          rgba(41, 137, 168, 1) 100%
  );
}
